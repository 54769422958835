import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"

import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`max-w-67-8 pt-3-2 md:pt-8 pb-4 md:pb-8`}
`

export const Title = tw.h1`
  text-28 leading-1.28 tracking-10 md:text-35 font-bold uppercase mb-4 md:mb-6 text-center
`

export const Content = styled(StyledContainer)`
  ${tw`flex flex-wrap-reverse mb-6 items-end`}
`

export const DetailsWrapper = tw.div`
  w-full lg:w-1/3 flex flex-wrap items-start justify-center max-h-64 overflow-x-hidden lg:pr-2
`

export const DetailsWrapperInner = tw.div`
  w-full lg:mr-2
`

export const MapWrapper = tw.div`
  relative w-full lg:w-2/3 h-42 lg:h-64 lg:pl-6 mb-3-6 md:mb-0
`

export const SpecialInformationWrapper = styled(StyledContainer)`
  ${({ layout }) => {
    switch (layout) {
      case "desktop":
        return tw`hidden lg:block pb-8`
      case "mobile":
        return tw`block lg:hidden pb-2`
      default:
        return null
    }
  }}
`

export const SpecialInformation = styled(RichText)`
  ${tw`bg-grey-light p-2-4 py-1-6`}
`
