import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledInput } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"
import { StyledButton } from "../../Styled/Button"

export const Container = tw.div``

export const InputInner = styled.div`
  ${tw`relative flex-1 mb-0-8 `}
  input {
    ${tw`w-full rounded border border-navy pl-4 pt-1-5 pb-1-6 bg-white text-16 leading-1.5 md:text-14 md:leading-1.71`}
    :focus {
      ${tw`bg-white`}
    }
    ::placeholder {
      ${tw`text-navy opacity-100`}
    }
  }

  svg.search-icon {
    ${tw`w-2 h-2`}
  }
`

export const Input = tw(StyledInput)`h-full`

export const StyledIcon = styled(Icon)`
  ${tw`absolute left-1-6 top-1-8 text-navy`}
`

export const UseMyCurrentLocation = tw.div`w-full mt-3 text-center cursor-pointer`

export const InputWrapper = tw.div`flex flex-col md:flex-row md:max-w-55-5 w-full mx-auto`

export const SearchButton = tw.div`
  md:ml-1-6 w-full mb-0-8 md:max-w-11-5
`

export const Button = styled(StyledButton)`
  ${tw`h-5-6`}
`
