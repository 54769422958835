import React from "react"

import { Wrapper, Title, Distance, Buttons, Button } from "./CardStyles"
import { RichText } from "../../RichText/RichText"
import { withCard } from "./withCard"
import { Link } from "gatsby"
import { SpecialOpenHours } from "../../SpecialOpenHours/SpecialOpenHours"

export const Card = withCard(
  ({
    id,
    title,
    distance,
    address,
    handleClick,
    storeUrl,
    specialTradingHours,
    additionalStoreDetailLinkText,
    showSpecialTradingHours,
  }) => (
    <Wrapper onClick={() => handleClick(id)}>
      <Distance>
        {distance && distance >= 0 ? `${distance.toFixed(2)}km` : null}
      </Distance>
      <Title>{title}</Title>
      {address && <RichText>{address}</RichText>}
      <Buttons>
        <Button
          size={"primary"}
          as={Link}
          to={storeUrl}
          title={additionalStoreDetailLinkText}
        >
          <span>{additionalStoreDetailLinkText}</span>
        </Button>
        {showSpecialTradingHours && (
          <SpecialOpenHours specialOpeningHours={specialTradingHours} />
        )}
      </Buttons>
    </Wrapper>
  ),
)
