import React, { useState, useEffect } from "react"
import { useApp } from "../../hooks/useApp"

import { useGeocode } from "../../hooks/useGeocode"

export const withStores = Component => ({ name = "Stores", page, stores }) => {
  const { queryGeocode, getDistance } = useGeocode()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { title, special } = page || {}

  const [mapStores, setMapStores] = useState([])
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  })
  const [selectedStore, setSelectedStore] = useState(null)

  useEffect(() => {
    if (stores) {
      const mapStores =
        stores.edges
          ?.map(({ node }, index) => ({
            ...node,
            id: String(index + 1),
            url: `${routes["STORE"]}/${node.slug.current}`,
            distance:
              currentLocation?.lat &&
              currentLocation?.lng &&
              node?.location?.lat &&
              node?.location?.lng
                ? getDistance(
                    currentLocation.lat,
                    currentLocation.lng,
                    node.location.lat,
                    node.location.lng,
                    "K",
                  )
                : -1,
          }))
          .sort((a, b) => a.distance - b.distance) || []
      setMapStores(mapStores)
    }
  }, [stores, currentLocation, getDistance, routes])

  const handleSearch = async query => {
    const location = await queryGeocode(query)
    if (location?.lat && location?.lng) {
      setSelectedStore(null)
      setCurrentLocation(location)
    }
  }

  const handleClick = id => {
    const store = mapStores?.find(store => store.id === id)
    if (store) {
      setSelectedStore(store)
    }
  }

  Component.displayName = name
  return (
    <Component
      title={title}
      handleSearch={handleSearch}
      handleUpdateCurrentLocation={setCurrentLocation}
      handleClick={handleClick}
      stores={mapStores}
      currentLocation={
        currentLocation?.lat && currentLocation?.lng ? currentLocation : null
      }
      selectedStore={selectedStore}
      mapStores={mapStores}
      special={special}
    />
  )
}
