import React from "react"

import { withStoreCardList } from "./withStoreCardList"
import { Card } from "../Card/Card"
import { Wrapper } from "./StoreCardListStyles"

export const StoreCardList = withStoreCardList(
  ({ stores, selectedStore, handleClick }): JSX.Element => (
    <Wrapper>
      {stores &&
        stores?.map(store => (
          <Card
            key={store.id}
            selected={selectedStore && selectedStore.id === store.id}
            handleClick={handleClick}
            {...store}
          />
        ))}
    </Wrapper>
  ),
)
