import React from "react"

import { withStores } from "./withStores"
import { Map } from "../Map/Map"
import { StoreCardList } from "./List/StoreCardList"
import { StoreForm } from "./Form/StoreForm"
import {
  Wrapper,
  Title,
  Content,
  DetailsWrapper,
  DetailsWrapperInner,
  MapWrapper,
  SpecialInformationWrapper,
  SpecialInformation,
} from "./StoresStyles"

export const Stores = withStores(
  ({
    title,
    stores,
    handleSearch,
    handleUpdateCurrentLocation,
    handleClick,
    selectedStore,
    mapStores,
    special,
  }): JSX.Element => (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <StoreForm
          handleSearch={handleSearch}
          handleUpdateCurrentLocation={handleUpdateCurrentLocation}
        />
      </Wrapper>
      <SpecialInformationWrapper layout={"mobile"}>
        <SpecialInformation>{special}</SpecialInformation>
      </SpecialInformationWrapper>
      <Content>
        <DetailsWrapper>
          <DetailsWrapperInner>
            <StoreCardList
              stores={stores}
              selectedStore={selectedStore}
              handleClick={handleClick}
            />
          </DetailsWrapperInner>
        </DetailsWrapper>
        <MapWrapper>
          {mapStores.length > 0 ? (
            <Map
              location={selectedStore ? selectedStore.location : null}
              selectedStore={selectedStore}
              mapStores={mapStores}
            />
          ) : null}
        </MapWrapper>
      </Content>
      {special?.length > 0 ? (
        <SpecialInformationWrapper layout={"desktop"}>
          <SpecialInformation>{special}</SpecialInformation>
        </SpecialInformationWrapper>
      ) : null}
    </>
  ),
)
