import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withCard = Component => ({
  name = "Card",
  id,
  url,
  title,
  distance,
  address,
  specialTradingHours,
  handleClick,
}) => {
  const { pageStores } = useStaticQuery(graphql`
    query SANITY_PAGE_STORES_CARD_LIST {
      pageStores: sanityPageStores {
        additionalStoreDetailLinkText
        additionalSpecialOpeningHoursButtonText
      }
    }
  `)

  const {
    additionalStoreDetailLinkText,
    additionalSpecialOpeningHoursButtonText,
  } = pageStores || {}

  const showSpecialTradingHours = useMemo(() => {
    if (specialTradingHours?.length > 0 && specialTradingHours?.children?.every(child => child?.text !== "")) return true
    return false
  }, [specialTradingHours?.children, specialTradingHours?.length])

  Component.displayName = name
  return (
    <Component
      id={id}
      title={title}
      distance={distance}
      address={address}
      handleClick={handleClick}
      storeUrl={url}
      specialTradingHours={specialTradingHours}
      showSpecialTradingHours={showSpecialTradingHours}
      additionalStoreDetailLinkText={additionalStoreDetailLinkText}
      additionalSpecialOpeningHoursButtonText={
        additionalSpecialOpeningHoursButtonText
      }
    />
  )
}
