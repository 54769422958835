import tw, { styled } from "twin.macro"
import { UnderlinedButton } from "../../Styled/Button"

export const Wrapper = styled.div`
  ${tw`relative w-full pt-3-2 pb-4 text-left border-t border-navy border-opacity-20 cursor-pointer`}
  :first-of-type {
    ${tw`border-t-0`}
  }
`

export const Distance = tw.div`
  absolute top-2-8 right-0 text-14 text-grey leading-1.5 tracking-10 mb-1-2
`

export const Title = tw.h3`
  text-20 leading-1.5 tracking-10 font-bold uppercase mb-1-2
`

export const Buttons = tw.div`
  mt-1-6 flex flex-row justify-start
`

export const Button = styled(UnderlinedButton)`
  ${tw`mr-4`}
`
