import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "../components/Stores/Stores"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageStores {
      title
      special: _rawSpecial(resolveReferences: { maxDepth: 6 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    stores: allSanityStore(sort: { fields: index, order: ASC }) {
      edges {
        node {
          ...GatsbyStoreFragment
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("stores_page")

  return <Page {...props} {...data} />
}

export default Component
