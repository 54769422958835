import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

export const withStoreForm = Component => ({
  name = "StoreForm",
  handleSearch,
  handleUpdateCurrentLocation,
}) => {
  const [query, setQuery] = useState("")

  const { pageStores } = useStaticQuery(graphql`
    query SANITY_PAGE_STORES_SEARCH_FORM {
      pageStores: sanityPageStores {
        additionalSearchButtonText
        additionalSearchPlaceholderText
        additionalUseCurrentLocationButtonText
      }
    }
  `)

  const {
    additionalSearchButtonText,
    additionalSearchPlaceholderText,
    additionalUseCurrentLocationButtonText,
  } = pageStores || {}

  const handleChange = event => {
    setQuery(event.target.value)
  }

  const handleSubmit = () => {
    handleSearch(query)
  }

  const handleCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      handleUpdateCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  Component.displayName = name
  return (
    <Component
      query={query}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleCurrentPosition={handleCurrentPosition}
      additionalSearchButtonText={additionalSearchButtonText}
      additionalSearchPlaceholderText={additionalSearchPlaceholderText}
      additionalUseCurrentLocationButtonText={
        additionalUseCurrentLocationButtonText
      }
    />
  )
}
