import React from "react"

import { withStoreForm } from "./withStoreForm"
import {
  Container,
  InputWrapper,
  InputInner,
  Button,
  StyledIcon,
  SearchButton,
  UseMyCurrentLocation,
} from "./StoreFormStyles"
import { UnderlinedButton } from "../../Styled/Button"

export const StoreForm = withStoreForm(
  ({
    handleSubmit,
    query,
    handleChange,
    handleCurrentPosition,
    additionalSearchButtonText,
    additionalSearchPlaceholderText,
    additionalUseCurrentLocationButtonText,
  }): JSX.Element => (
    <Container>
      <InputWrapper>
        <InputInner>
          <StyledIcon name={"searchStore"} />
          <input
            placeholder={additionalSearchPlaceholderText}
            value={query}
            onChange={handleChange}
          />
        </InputInner>
        <SearchButton>
          <Button onClick={handleSubmit} size={"primary"} wide={"true"}>
            {additionalSearchButtonText}
          </Button>
        </SearchButton>
      </InputWrapper>
      <UseMyCurrentLocation>
        <UnderlinedButton onClick={handleCurrentPosition}>
          <span>{additionalUseCurrentLocationButtonText}</span>
        </UnderlinedButton>
      </UseMyCurrentLocation>
    </Container>
  ),
)
