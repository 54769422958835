import React from "react"

export const withStoreCardList = Component => ({
  name = "StoreCardList",
  stores,
  selectedStore,
  handleClick,
}) => {
  Component.displayName = name
  return (
    <Component
      stores={stores}
      selectedStore={selectedStore}
      handleClick={handleClick}
    />
  )
}
